.pare-maior {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 50%;
  border: 5px solid red;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pare-maior::before {
  content: "";
  position: absolute;
  width: 110%;
  height: 3px;
  background-color: red;
  transform: rotate(-45deg);
}

.pare {
  position: absolute;
  width: 45px;
  height: 45px;
  left: -8px;
  opacity: 50%;
  border: 5px solid red;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pare::before {
  content: "";
  position: absolute;
  width: 110%;
  height: 3px;
  background-color: red;
  transform: rotate(-45deg);
}

.pare2 {
  position: absolute;
  width: 70px;
  height: 70px;
  left: -14px;
  opacity: 50%;
  border: 5px solid red;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pare2::before {
  content: "";
  position: absolute;
  width: 110%;
  height: 3px;
  background-color: red;
  transform: rotate(-45deg);
}
